/*cta*/
/********/

.cta1{
  background-color: #63d6e4;  
  color:#fff;

  h2{
    margin-bottom: 20px;
  }

  .cta-buttons button{
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: $primary-border-radius;
    padding: 5px 14px;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: $text__size--eta;
  }

}

.cta2{
  background-color: $color__primary;
  padding:60px 0;

  h4{
    color: #fff;
    @media (min-width:992px){
     text-align: left; 
    }
  }

  .small-sub{
    color: $color__primary--light;
    font-size: $text__size--eta;
    font-style: italic;
    display: block;

    @media (min-width:992px){
     text-align: left; 
    }
  }
  .cta2-text{
    // float:right;
  }
  .cta2-buttons{
    margin-top: 10px;
  }

  .btn-mountain{
    margin-right: 20px;
  }
}


.cta3{
  @extend .section;
  background: $color__primary url('../images/cta/cta-3.jpg');
  color: #fff;
  position: relative;
  background-size: cover;
  background-position: center center;
  
  &:before{
    @include overlay;
  }
  
  .btn-mountain-flat{
    margin-right: 20px;
  }

  h3{
    margin-bottom: 30px;
    font-weight: 600;
  }

  span{
    display: inline-block;
  &.more-link a{
    
    border-bottom: 1px solid #FFF;
    text-decoration: none;
    font-size: $text__size--eta;
    color: #fff;
    text-transform: capitalize;
  }
}

  .btn-mountain{
    margin-right: 20px;
  }
}
.cta4{
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f8f8f8;

  @media (min-width: 768px){
    .col-sm-5{
      text-align: right;
    }
  }

  @media (max-width: 767px){
  text-align: center;
    img{
      margin-bottom: 25px;
    }
  }
  .title-subtitle{
    h2{
      margin-bottom: 0;
    }
    p{
        margin-bottom: 25px;
        margin-top: 0;
    }
  }

}



.share-on {
    background: $white;
    text-transform: uppercase;
    font-size: $text__size--delta;
    padding: 20px 0;
    color: $color__primary--dark;
    font-family: $alt_font;
    a{
      display: inline-block;
      margin: 15px;
      color: $accent;
    }
}


.cta5{
  p{
    margin: 0;
    background-color: $color__primary;
    font-size: 45px;
    padding: 60px;
    color: #efefef;
    line-height: 1.12358;
    font-style: italic;
    @media (max-width: 768px){
      font-size: $text__size--gamma;
      padding: 25px 15px;
    }
  }
}

.cta6{
  padding: 80px 0;
  img{
    width: 200px;
    padding-bottom: 80px;
  }
  .section-title{
    span{
      color: $accent;
    }
  }
  p{
    font-size: $text__size--delta;
    line-height: 1.61;
    padding: 0 0 45px;
  }
  .btn{
    margin: 15px;
  }

.ctaFunFact{

  .fact-wrap,.fact-wrap-devider{
    display: inline-block

  }

  .fact-wrap{
    .fact-number span{
      font-size: $text__size--alpha;
      font-weight: 300;
      line-height: 1;
      display: inline-block;
      margin-bottom: 10px;
      color: #999;
    }
    .fact-text span{
      font-size: $text__size--epsilon;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  span.fact-wrap-devider{
    font-family: $title_font;
    font-size: 115px;
    line-height: 0.9;
    display: inline-block;
    margin: 0 40px;
  }

  .fact-para p{
    width: 60%;
    margin: 40px auto 56px;
    font-size: $text__size--delta;
    font-weight: 300;
    line-height: 1.5;
  }

  .trial-btn, .subscribe-btn{
        padding: 0;
        border: none;
        display: block;

        @media (min-width: 768px){
          display: inline-block;
        }
      }

      .trial-btn a, .subscribe-btn a{
        text-transform: uppercase;
        font-size: $text__size--epsilon;
        letter-spacing: 1px;
        color: $white;
        padding: 9px 30px;

        @media (min-width: 600px){
          padding: 9px 73px;
        }
      }

      .trial-btn a{
        background-color: $accent;
      }

      .subscribe-btn a{
        background-color: #be0f00;
      }

      .btn-devider{
        display: inline-block;
        margin: 15px 0;
        color: #999;
        font-size: $text__size--eta;
        text-transform: uppercase;
        font-size: $text__size--epsilon;

        @media (min-width: 768px){
           margin: 0 15px;
        }
      }
}
}
.full-width-social-icons{
      background-color: #f4f4f4;
  a{
    display: block;
    .box-icon{
      padding: 30px 0;
      text-align: center;
      color: $color__primary--dark;
      @include smoothTransition();
      .icon{
        font-size: 32px;
      }
    }
    &:hover{
      text-decoration: none;
      .box-icon{
        background-color: $accent;
        color: #fff;
      }
    }
  }
}

.opening-time{
  color: #fff;
  p{
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.75);
    span{
      font-weight: 700;
      color: #fff;
    }
  }
}