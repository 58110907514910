/*Partners*/
/**********/
.partners{
  background-color: $color__primary;
  padding: 95px 0;
  position: relative;
  .p-logo{
    text-align: center;
    width: 100%;
    border-radius: $primary-border-radius;
    img{
      width: auto !important;
      margin: auto;
    }
  }
  #partners-slider{
    .item{
      margin: 0 10px;
    }
  }
}

.landing-layout1-logo{
  background-color: #f4f4f4;
}

.logo-borderd-grid{

  @media (min-width: 992px){

    .first-row{    
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
  }

  .logo-wrap{
    text-align: center;
    padding: 13px 48px 0;
    height: 100px;

    img{
      display: inline-block;
      opacity: 0.7;
    }

    @media (min-width: 768px){
      padding: 13px 86px 0;
    }

    @media (min-width:992px){
      min-height: 100px;
      padding: 13px 40px 0;
      border-right: 1px solid rgba(0,0,0,0.1);
    }

    @media (min-width:1200px){
      padding: 13px 63px 0;
    }
  }

  .col-md-2:last-child .logo-wrap{
    border-right: none;
  }

  .second-row .logo-wrap{
    padding-top: 22px;
  }

}