/* .dot-menu{
	.menuzord{
		@media (min-width:992px){
			position: fixed;
			top:0;
			right:0;
			width:auto;
			height: auto;
			top:50%;
			transform: translatey(-50%);

			.menuzord-brand{
				display: none;
			}
			.menuzord-menu{
				li{
					width:12px;
					height: 12px;
					display: block;
					float: none;
					margin-bottom: 20px;


					&.active a{
						background-color: $accent__light;
					}

					&:hover{
						span{
							right: 30px;
							opacity: 1;
						}
						a{
							background-color: $color__primary;
						}
					}


					a{
						position: relative;
						width: 12px;
						height: 12px;
						border-radius: 50%;						
						background-color: rgb(164, 167, 169);
						padding: 0;
						transition: all 0.4s;

						span{
							position: absolute;
							right: 25px;
							top:-3px;
							white-space: nowrap;
							background-color: $accent;
							border-radius: $primary-border-radius;
							opacity:0;
							transition: all 0.4s;
						}

					}
				}

			}

			.menuzord-brand{
				margin:60px 30px 0 35px;
			}

			&.navbar-solid,.solid{
				background-color: transparent;
				ul{
					padding-top:0;
					li a{
						padding: 0;
					}
				}
				.menuzord-brand{
					margin:60px 30px 0 35px;
					img{
						width: 100px;
					}
				}
			}
		}

	}
} */


#dot-nav{
	background-color: #e6e6e6;
    border-radius: 15px;
	right: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 999;

	ul {
		list-style: none;
		margin:0;
		padding: 0;

		li {
			position: relative;
			background-color: $white;
			border:1px solid $accent__light;
			border-radius: 100%;
			cursor: pointer;
			padding: 5px;
			height: 5px;
			margin: 15px 5px;
			width: 5px;
			vertical-align:bottom;

			&:hover, &.active {
				background-color: $accent;
			}

			a {
				outline: 0;
				vertical-align:top;
				margin: 0px 0px 0px 25px;
				position: relative;
				top:-5px;
			}

			.awesome-tooltip + .tooltip > .tooltip-inner {
			    background-color: #8e44ad; 
			    color: #f5f5f5; 
			    border: 1px solid #8e44ad; 
			}
			.awesome-tooltip + .tooltip.left > .tooltip-arrow{
			    top:50%;
			    right:0;
			    margin-top:-5px;
			    border-top:5px solid transparent;
			    border-bottom:5px solid transparent;
			    border-left:5px solid #8e44ad;
			}
		}
	}
}