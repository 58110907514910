/*Necessary Fonts*/
/*****************/
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic);

/*Global Settings*/
/*****************/
*{
  outline: none;
}
html{
  font-size: 16px;
}
 
body{
  font-family: $body_font;
  color: $color__primary;
}
@media (min-width: 48rem){
  .m-t-0{
    margin-top: 0 !important;
  }
}

h1,h2,h3,h4,h5{
  font-family: $title_font;
  font-weight: 700;
  margin-bottom: 0.42em;
}
h6{
  font-family: $alt_font;
  text-transform: uppercase;
  letter-spacing: 1px;
  
}

/*Type-Scale [Augmented Fourth]*/
/*******************************/
h1{
  font-size: $text__size--gamma;

  @media (min-width:768px){
   font-size: $text__size--alpha; 
  }
}
h2{
  font-size: $text__size--gamma;
  @media (min-width:768px){
    font-size: $text__size--beta;
  }  
}
h3{

  font-size: $text__size--delta;
  @media (min-width: 768px){
    font-size: $text__size--gamma;
  }
}
h4{
  font-size: $text__size--delta;
  line-height: $text__size--delta*1.5;
}
p{
  font-size: $text__size--eta;
  line-height: 1.414em;
  font-weight: 400;
}
h5{
  font-size: $text__size--eta;
}
h6{
  font-size: $text__size--epsilon;
}
small{
  font-size: $text__size--zeta;
}

.font-title{
  font-family: $title_font;
}
.alt_font{
  font-family: $alt_font;
}

ul.plain-list {
    list-style: initial;
    padding-left: 30px;
}

blockquote p {
    font-style: italic;
    font-size: $text__size--delta;
}

p.lead {
  font-size: $text__size--delta;
  line-height: $text__size--delta*1.5;
  margin-bottom: 40px;
  font-weight: 400;

  &.dropcap:first-letter {
    float: left;
    line-height: $text__size--alpha;
    margin-right: 10px;
    font-size: $text__size--alpha;
  }
}

.text-capital{
  text-transform: uppercase;
}

.title-subtitle{
  h2{
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 0;
  }
  p{
    font-weight: 400;
    font-size: 1.414rem;
    margin-top: 0;
    font-style: italic;
    line-height: 1.4;
    margin-bottom: 60px;
  }
}