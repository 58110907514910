.showcase-cta{
	background-image: url('../images/showcase/cta-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: #fff;
}

.animatedbg {
    position: absolute;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 3000%;
    transform: translate3d(0, 0, 0);
    animation: moveSlideshow 2000s linear infinite;
    background-image: url("../images/showcase/showcase-header.jpg");
    background-size: contain;
    background-position: center center;
}

.showcase {
    overflow-x: hidden;
    width: 100%;
    position: relative;
}

@keyframes moveSlideshow { 
  0%{
    transform: translateX(0);  
  } 
  100% { 
    transform: translateX(-66.6666%);  
  }
}


.showcase-section{
	padding-top: 80px;
	padding-bottom: 20px;
	// background-color: #f4f4f4;
	a {
		display: block;
		&:hover, &:focus{
			text-decoration: none;
		}
	}
	.title{
		margin-bottom: 50px;
	}
	.showcase-item{
		margin-bottom: 60px;
		position: relative;
		background-color: #fff;
		.item{
			position: relative;
    		overflow: hidden;

			.img-wrap{
				border: 1px solid rgba(0,0,0,0.1);
				padding: 0;
				background-color: #fff;
			}
			.item-title{
				h5{
					transition: all 0.4s;
					padding: 20px 30px 30px;
					color: #111;
					font-family: $alt_font;
					font-weight: 500;
				}
			}
				.new-arrived {
				    position: absolute;
				    top: 56px;
				    left: -21px;
				    transform: rotateZ(-45deg);
				&:after{
					content: 'NEW';
				    background-color: yellow;
				    padding: 8px 40px;
				    font-size: 10px;
				    font-weight: 400;
				    line-height: 1;
				    color: #000;
				    letter-spacing: 1px;
				    position: absolute;
				    font-family: Montserrat, sans-serif;
				}
			}
		}
		&:hover, &:focus {
			a{
				text-decoration: none;
			}
			.item{
		    	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
				.item-title h5{
					color: $accent;
				}
			}
		}
	}
}
.showcase-header{
	background-color: black;
	background-repeat: no-repeat;
	background-position: center;
	color: #fff;
	position: relative;
	&:before{
		background-color: rgba(44, 44, 44, 0.8);
		z-index: 1;
	}
	.intro-box{
		.intro {
		    height: 70vh;
		}
	}
	h1{
		font-size:45px;
		letter-spacing: -5px;
		margin-bottom: 0;
		color: #fff;
		position: relative;
		display: inline-block;
		margin-top: 80px;

		span.v-marker{
	    	position: absolute;
		    font-size: 24px;
		    background-color: $accent;
		    border-radius: 100%;
		    width: 50px;
		    height: 50px;
		    text-align: center;
		    top: -10px;
		    right: -40px;
		    line-height: 53px;
		    letter-spacing: 0;

		    @media (max-width:767px){
		    	font-size: 12px;
			    width: 30px;
			    height: 30px;
			    line-height: 32px;
			    right: -30px;
		    }
		}

		@media (min-width:768px){
			font-size: 100px;
			margin-top: 100px;		
		}
	}
	h3{
		margin-bottom: 45px;
		margin-top: 0;
	}
	p{
		font-size:18px;
		width:90%;
		font-weight: 200;		
		margin: 0 auto;
		line-height: 1.4;
		margin-bottom: 40px;
		@media (min-width:768px){
			font-size: 24px;
			max-width: 400px;
		}
		color: #fff;
	}
}

.multi-page,.special-pages{
	background-color: #f7f7f7;
}
.menubar-solid{
	background-color: #000;
}