.portfolio-grid{
	position: relative;
}

.grid-sizer{
	width:100%;

	@media (min-width: 767px){
		width: 50%;
	}

	@media (min-width: 992px){
		width: 25%;
	}
}

.no-padding .portfolio-grid-item{
	padding: 0;
}

.no-padding .load-more-portfolio{
	right: 0;
	bottom: 8px;
}

.portfolio-grid-item{
	// width:100%;
	padding: 15px;
	// float: left;
	// overflow: hidden;
	// box-sizing: padding-box;

	@media (min-width: 767px){
		width: 50%;
	}

	@media (min-width: 992px){
		width: 25%;
	}

	&.grid-item-2x{
		width: 100%;

		@media (min-width: 768px){
			width: 50%;
		}

		@media (min-width: 992px){
			width: 50%;
		}
	}

}

.portfolio-thumb:hover .portfolio-hover{
	opacity: 1;
}


.load-more-portfolio{
	position: absolute;
	bottom:15px;
	right:15px;
	// width: calc(100% - 30px);
	border: none;
	background-color: rgba($accent,0.8);
	color: $white;
	font-size: 48px;
	// z-index: 9999;
	border-right: 1px solid rgba($white,0.1);
	border-bottom: 1px solid rgba($white,0.1);
	box-shadow: 7px 7px 0px 0px $accent; 
}

.load-more-content{
	display: block;
	font-size: $text__size--delta;
}

.portfolio-thumb{
	position: relative;
}

.portfolio-hover{
	position:absolute;
	background-color: rgba($accent,0.9);
	width:100%;
	height: 100%;
	top:0;
	left:0;
	right:0;
	bottom:0;	
	opacity: 0;
	transition: all 0.4s;
}

.vcenter{
	position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.4s;

    a{
    	position: relative;
	    width: 100%;
	    height: 100%;
	    display: table;
	    text-decoration: none;
    }
}

.portfolio-btn-group-wraper {
    border: 2px solid #f1f1f1;
    padding: 10px 0;
    border-left: 0px;
    border-right: 0px;
    margin-bottom: 50px;
}
.sortable-portfolio-button{
	button{
		background-color: transparent;
	    color: #767676;
	    border-radius: 0px;
	    border: none;
	    text-transform: uppercase;
	    padding: 5px 10px;
	    letter-spacing: 1px;
	    margin-right: 0px;
	    transition: all 0.4s;
	    font-size: 11px;
	    font-family: $alt_font;
		&:after{
			content:'/';
			margin-left: 15px;
		}
		@media (min-width:600px){
			margin-bottom: 0;
		}

		&:hover{
			color: $accent;
		}
	}

}

/*----------------  Single Portfolio Page  ------------------*/

.portfolio-description{
	display: table-cell;
    color: #fff;
    vertical-align: bottom;
    padding:15px;

    h5{
	    line-height: 1;
	    margin-bottom: 0;
	    font-family: $alt_font;
    }
    p{
	    text-transform: capitalize;
	    font-style: italic;
	    color: rgba(255, 255, 255, 0.84);
    }
}

.projects{
	.section-title{
		margin-bottom: 60px;
	}
	#projects-slider{
		.owl-dots{
			margin: 10px auto;
    		text-align: center;
			.owl-dot{
			    display: inline-block;
			    background: #000;
			    position: relative;
			    width: 12px;
			    margin: 5px;
			    height: 12px;
			    overflow: hidden;
			    border-radius: 100%;
			    &.active{
			    	background: $accent;
			    }
			}
		}
		/*position: relative;
		.owl-nav{
			.owl-prev, .owl-next{
				position: absolute;
				top: 50%;
			}
			.owl-prev{
				left: -10px;
			}
			.owl-next{
				right: -10px;
			}
		}*/
	}
}

.featured-img {
	padding-top: 60px;
}

.projectDetails {
	.section-title {
		margin-bottom: 15px;
	}
	.video-container{
	    position: relative;
	    width: 100%;
	    height: 1px;
	    padding-bottom: 62.5%;

	    iframe{
		    position: absolute;
		    width: 100% !important;
		    height: 100% !important;
		}
	}

	.owl-nav{
		.owl-prev, .owl-next{
			position: absolute;
			top: calc(50% - 35px);
			background-color: transparent;
			width: 60px;
			color: rgb(167, 174, 184);
			text-align: center;
			font-size: 50px;
			transition: all 0.4s ease-in-out;
		}
		&:hover{
			.owl-prev, .owl-next{
				background-color: #fff;
				color: #111;
			}
		}
		.owl-prev{
			left: 0;
		}
		.owl-next{
			right: 0;
		}
	}

	.details {
		h4 {
			margin: 15px 0px 10px 0px;
			text-decoration: underline;
		}

		ul {
			li {
				padding: 4px 0px;
			}
		}
	}
}

.project-feature {
	.features {
		h5 {
			i {
			    font-size: 40px;
			    margin-right: 15px;
			    color: $accent;
			}
		}
	}
}