.side-menu{
	.content-wrapper{
		@media (min-width:768px){
			margin-left: 260px;
		}
	}

	.container{
		width: auto;
	}

	.menuzord{
		@media (min-width:768px){
			position: fixed;
			top:0;
			left:0;
			width:260px;
			height: 100%;
			box-shadow: 0px 0 60px 0px rgba(0, 0, 0, 0.07);

			.menuzord-menu{
				width: 100%;
				padding-top: 40px;
				li{
					display: block;
					float: none;
					a{
						display: block;
    					color: gray;
    					&:hover, &:focus{
    						background: transparent;
    						color: white;
    					}
					}
				}

			}

			.menuzord-brand{
				margin: 60px 30px 0 16px;
			}

			&.navbar-solid,.solid{
				ul{
					padding-top: 40px;
					li a{
						padding: 26px 16px;
					}
				}
				.menuzord-brand{
					margin:60px 30px 0 35px;
					img{
						width: 100px;
					}
				}
			}
		}

		@media (max-width: 767px){
			background: white;
			li{
				a{
					color: black;
					text-align: center;
				}
			}
		}

	}


	@media (min-width: 1200px) {
		.container {
		    width: 992px;
		}
	}
}