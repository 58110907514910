.our-process1{
	background-color:#f7f7f7;

	h1{
		font-family: $title_font;
		margin-bottom: 45px;
		font-size: $text__size--beta;

		@media (min-width: 992px){
			margin-bottom: 90px;
		}
	}

	h3{
		font-size: $text__size--delta;
	}

	.process-wrap{
		margin-bottom: 60px;

		@media (min-width:992px){
			margin-bottom: 0;
		}
	}

	.col-md-4:last-child .process-wrap{
		margin-bottom: 0;
	}

	.process-img{
		position: relative;
		margin-bottom: 60px;
		width:270px;
		transition: all 0.6s;

		

		@media (min-width:992px){
			&:after{
			    position: absolute;
			    opacity: 0;
			    width: 100%;
			    content: '';
			    right: -100%;
			    top: 50%;
			    left:auto;
			    position: absolute;
			    border-left: none;
			    border-bottom: 1px dashed $color__primary--light;
			    transition: all 0.6s;
			}
		}

		&.last{
			&:after{
				content: none;
			}
		}

		&:hover:after{
			opacity:1;
		}

		}
	.process-wrap{
		position:relative;
		h3{
			font-weight: 500;
		}

		&:hover .process-step{
			background-color: $accent;	
		}
	}
	.process-step{
		position: absolute;
		top:190px;
		left:50%;
		transform: translateX(-50%);
		background-color: $color__primary--light;
		border-radius:50%;
		display: inline-block;
		width: 50px;
		height: 50px;
		font-family: $title_font;
		font-size: $text__size--delta;
		color: $white;
		transition: all 0.3s;

		span{
			display: inline-block;
			margin-top: 10px;
		}
	}
}