.pagination-wrap{
	border: 1px solid rgba(0,0,0,0.1);
	display: inline-block;
}
.pagination-box {
	display: inline-block;
	border-right: 1px solid rgba(0,0,0,0.1);
	a span{
		display: inline-block;
		padding: 10px 15px;
		color: #717171;
		transition: all 0.4s;

		@media (min-width: 500px){
			padding: 15px 20px;
		}

		&:hover{
			color: $accent;
		}
	}
	a i{
		padding: 10px 15px;
		display: inline-block;
		color: $color__primary--light;	
		transition: all 0.4s;

		@media (min-width: 500px){
			padding: 15px 20px;
		}

		&:hover{
			color: $accent;
		}	

		&:last-child{
			border-right: none;	
		}
	}

	&:last-child{
		border-right:none;
	}
}
