.latestNews1{
	h2{
		margin-bottom: 60px;
	}
}

.latest-news-wrap{
	padding: 15px 15px 30px 15px;;
	//border: 1px solid rgba(0,0,0,0.1);

	h3{
		margin-bottom: 50px;
		a{
			color: $color__primary--dark;
			font-size: $text__size--delta;
			font-weight: 600;
			text-decoration: none;
			transition: all 0.4s;

			&:hover{
				color: $accent;
			}
		}
	}
}

.news-media-wrapper{
	margin-bottom: 30px;

	&.latest-video{
		a{
			display: inline-block;
		}

		.latest-popup{
			position: relative;
			&:before{
				font-family: 'ionicons';
				content: '\f488';
				position: absolute;
				font-size:32px;
				color: $white;
				top:50%;
				left:50%;
				transform: translateX(-50%) translateY(-50%);
				border: 2px solid #fff;
			    border-radius: 50%;
			    line-height: 0;
			    padding: 30px 20px 20px 24px;
			}
		}
	}
}

.latest-news-meta{
	margin-bottom: 15px;
}

.latest-news-meta .meta{
	color: #717171;
	font-size: $text__size--eta;
	position: relative;
	padding: 0 10px 0 20px;
	display: inline-block;
	transition: all 0.3s;
	&:hover{
		color: $accent;
	}

	&:before{
		position: absolute;
		left:0;
		top: 0;
		content: '.';
		font-size:48px;
		font-weight: 600;
		line-height: 0;
		color: $color__primary--light;
	}
}

.latest-news-meta .meta.first{
	padding-left: 0;
	&:before{
		content: '';
	}
}



.author-wrap{
	position: relative;

	.author-name{
		position: absolute;
		top:50%;
		transform: translatey(-50%);
		left:60px;
		color: #717171;
		font-size: $text__size--eta;
		transition: all 0.4s;

		&:hover{
			color: $accent;
		}
	}

	.comments-number{
		position: absolute;
		right: 0;
		top:50%;
		transform: translatey(-50%);
		color: $color__primary--light;
		font-size: $text__size--eta;
		transition: all 0.4s;

		&:hover{
			color: $accent;
		}

		&:before{
			font-family: 'ionicons';
			content: '\f11f';
			position: absolute;
			left:-20px;
			top: 0;
			color: $color__primary--light;
		}
	}
}

.blog .latest-news-wrap{
	padding: 30px;
	margin-bottom: 30px;
	box-shadow: 0 0px 8px 1px rgba(0,0,0,0.1);
}

.blog .pagination1{
	margin-top: 60px;
}

.latest-news-wrap.text h3{
	margin-bottom: 30px;
}

.latest-text-wrapper{
	margin-bottom: 50px;
	line-height: 1.7;
}

.latest-news-wrap.quote{
	blockquote{
		font-size:24px;
		font-family:'Lato';
		font-style: italic;
		border-left:none;
	}
	cite{
		font-size: $text__size--eta;
		font-style: normal;
		color: $color__primary--light;
	}
}