.single-post{
	h1{
		font-size: $text__size--beta;
		font-family: lato;
		line-height: 1;
		margin-bottom: 40px;
	}

	.post-section{
		margin-bottom: 120px;

		&:last-child{
			padding-bottom: 60px;
			border-bottom: 1px solid rgba(0,0,0,0.1);
			margin-bottom: 0;
		}
	}

	.author-wrap{
		margin-bottom: 10px;
	}

	.latest-news-meta {
	    margin-bottom: 5px;
	}

	.featured-img-wrap{
		margin-bottom: 60px;
	}
	.post-img-wrap{
		margin-bottom: 30px;
	}
	p{
		color: $color__text--dark;
		line-height: 2;
		margin-bottom: 30px;

		&.last-para{
			margin-bottom: 50px;
		}
	}

	h2{
		font-family: lato;
		font-size: $text__size--delta;
		font-weight: 600;
		margin-bottom: 10px;
	}

	h3{
		font-family: lato;
		font-size: $text__size--delta;
		font-weight: 400;
		color: $color__primary--light;
		margin-bottom: 30px;
	}

	ul{
		padding-left: 30px;

		li{
			position: relative;
			font-size: $text__size--eta;
			color: $color__text--dark;
			line-height: 2;
			margin-bottom: 10px;

			em{
				font-style: normal;
				font-weight: 600;
				color: $color__primary--dark;
			}

			&:before{
				    position: absolute;
				    content: '.';
				    font-size: 80px;
				    color: #111;
				    line-height: 0;
				    top: -10px;
				    left: -30px;
			}

			}

	}
}

.singleBlog2.single-post{
	h1{
		margin-bottom: 20px;
	}

	.latest-news-meta{
		position: absolute;
		right: 0;
		top:50%;
		transform: translatey(-50%);
	}

	.author-wrap{
		margin-bottom: 40px;
	}

}

.tag-list-body{
	margin-top: 60px;
}

.section-post-meta{
	.tag-list{
		margin-bottom: 60px;
	}
	padding-bottom: 60px;
}