/*Icon-col-3*/
/************/
.icon-3-col{
  @media (min-width: 48rem){
    .row{
      margin-top: 40px;
    }
  }
  .item{
    .row{
      padding: 15px 0;
      transition: background-color 0.2s ease-in-out;
      border-radius: 3px;
    }
    &:hover{
      .row{
        background: #fafafa;
      }
    }
    h4{
     margin-bottom: 10px;
     margin-top: 0px;
     font-weight: 500;
     line-height: 1.2;
    }
    .icon{
      font-size: 3.998rem;
      text-align: center;
      position: relative;
      overflow: hidden;
      transition: all 0.4s ease-in-out;
      margin: 0 -15px;
      
      .line-top, .line-bottom{
        position: absolute;
        width: 6px;
        height: 20px;
        left: calc(50% - 3px);
        background-color: $accent;
        transition: all 0.4s ease-in-out;
      }
      .line-top{
        //top: -20px;
        transform: translateY(-20px);
      }
      .line-bottom{
        //bottom: -20px;
        transform: translateY(0px);
      }
    }
    
    &:hover{
      .icon{
        color: $accent;
        
        .line-top{
          //top: -5px;
          transform: translateY(-5px);
        }
        .line-bottom{
          //bottom: -5px;
          transform: translateY(-15px);
        }
        .line-top, .line-bottom{
          display: block;
        }
      }
    }

    p.desc{
      font-weight: 400;
      color: #5d5d5d;
    }
    
    h6.sub{
      color: #757576;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
}



/*small icon 3 col*/
/************/

.fswrap{
  position:relative;
  margin-bottom: 40px;

  @media (min-width: 992px){
    &.last-col{
      margin-bottom: 0;
    }
  }

  p{
    color: $color__primary--light;
    width: 90%;
  }

  h4{
    font-size: $text__size--eta;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 5px 0 10px 0;
    padding-left:40px;
    line-height: 36px;
  }
    .fsicon{
    position:absolute;
    font-size:30px;
    color: $accent;
  }
}


.service-3{
  span{
    font-size: 44px;
    color: $accent;
  }
  h4{
    position: relative;
    margin-bottom: 35px;
    font-weight: 700;

    &:after{
      position: absolute;
      left: calc(50% - 25px);
      bottom: -15px;
      content: '';
      width: 50px;
      height: 2px;
      background-color: $accent;
    }
  }
}

.features-big-img{

  h2,h3{
    text-align: center;
  }

  

  h3{
    font-size: $text__size--delta;
    font-weight: 300;
    margin-bottom: 70px;
  }
  .single-feature-warp{
    p{
      font-weight: 400;
      font-family: $body_font;
      color: #5d5d5d;
    }
    h5{
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }

  .big-img{
    text-align: center;
    margin-bottom: 60px;

    @media (min-width: 768px){
      margin-bottom: 100px;
    }

    img{
      display: inline-block;
      margin-left: -20px;
      margin-left: -38px;

      @media (min-width: 768px){
        height: 370px;
      }
    }

  }
}

  .single-feature-warp{

    @media (min-width: 768px){
      margin-bottom: 30px;
    }
  }


  .feature-number{
    display: inline-block;
    font-size: 65px;
    line-height: 0.7;
    color: #dcdcdc;
    padding-left: 10px;
  }


.largeIcon4col-feature{
  text-align: center;
  .icon-top-wiget{
    padding:25px 0;
  }
  .title{
    margin-bottom: 5px;
    font-family: $title_font;
    font-weight: 700;
  }
  .icon-wraper{
    display: table;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background-color: #eee;
    border-radius: 50%;
    margin-bottom: 25px;
    i{
      font-size: 30px;
      display: table-cell;
      vertical-align: middle;
    }
  }
}
.icon-top-wiget:hover{
  .icon-wraper{
    background-color: $accent;
    transition: all 0.2s ease-in-out;
    i{
      color: #fff;
    }
  }
}

.feature-largeImage4col{
  .content-wraper {
    margin-top: 15px;
  }
}
.feature-largeImage4col{
  .wiget-wraper{
    padding:15px;
  }
}
.feature-with-list-wraper{
  h2{
    margin-top: 0;
  }
  p{
    margin-bottom: 25px;
    margin-top: 15px;
  }
}
ul.feature-list-item{
    li{

      line-height: 1.5rem;
      font-weight: 400;
      i{
        padding-right: 10px;
        font-size: 1rem;
        color: #007fbe;
      }

      @media screen and (min-width: 769px){
        font-size:1rem;
      }
    }
}



