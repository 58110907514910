.ourExpertise1{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;

	&:before{
		content: '';
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.5);
	}


	.expertise-header{
		margin-bottom: 40px;
		h3{
			font-weight: 700;
		}
	}

	h4{
		text-transform: uppercase;
		text-transform: uppercase;
	    font-family: Montserrat, sans-serif;
	    letter-spacing: 0px;
	    font-size: 1rem;
	    font-weight: 700;
	    margin-top: 10px;
	}

	h2,h3,h4,p{
		color: $white;
	}

	p{
		font-size: 1.25rem;
	    line-height: 1.414;
	    font-weight: 300;
	    margin-bottom: 15px;
	    margin-bottom: 60px;
	}

	.circle-wrapper{
	    margin: 0 auto;
	    position: relative;
	    text-align: center;

	    @media (min-width: 768px){
	    	margin-top: -30px;
	    }
	     @media (min-width: 992px){
	    	width: 75%;
	    	margin-top: -30px;
	    }
	}

	a.default-mountain-link{
		color: $white;
	}
}
.bg-white-experties{
	h2,h3,h4,p{
		color: #111;
	}
}
#expertise-circle,#expertise-circle-2,#expertise-circle-3{
	margin-top: 30px;
	margin-right: 30px;

	@media (min-width: 768px){
		margin-top: 0;
		float: left;
	}
}
.expertise{
	border-color: #333!important;
	color: #333!important;
}