/*fun facts*/
/********/

.funfacts{
  background-attachment: fixed;
  background-size: cover; 
  position: relative;
  background-position: center;

  &:before{
    @include overlay;
  }

  i {
    color: $white;
    font-size: $text__size--alpha;
  }

  .number{
    font-family: $title_font;
    color: #ffffff;
    font-size: $text__size--beta;
    line-height: 0.707;
    margin: 15px 0px 10px 0px;
    font-weight: 700;
  }

  .count-description{
    color: #f8f8f8;
    font-size: $text__size--delta;
    font-weight: 400;
  }

  .counter-box{
    position: relative;
    margin-bottom: 60px;

    @media (min-width: 768px){
      margin-bottom: 0;
    }

    @media (min-width:1200px){
      
    }
    
  }

  .single-counter:last-child .counter-box{
    &:after{
      content: '';
    }
  }
}

.funfact-3{
  color: #fff;
  .single-counter{
    padding: 45px 0;
    i{
      font-size: 45px;
    }
    .count{
      font-size: 30px;
      position: relative;
      margin-bottom: 30px;
      &:after{
        content: '';
        position: absolute;
        height: 2px;
        background-color: #fff;
        width: 40px;
        left: calc(50% - 20px);
        bottom: -15px;
      }
    }
  }
  .fun-1{
    background-color: $accent;
  }
  .fun-2{
    background-color: $accent*0.75;
  }
  .fun-3{
    background-color: $accent*0.5;
  }
  .fun-4{
    background-color: $accent*0.25;
  }
}