.video{
	position: relative;
	.p-r-0 {
		padding-right: 0px;
		position: absolute;
		height: 100%;
		top: 0;
	}
	.video-text{
		padding-top: 0;
		padding-bottom: 0;
		@media (max-width:767px){
			background:transparent!important;
		}
	}
	.partial-bg{
		padding-right: 0;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
	    height: 100%;
	    display: table;
		.bg-wrapper {
		    display: table-cell;
		    text-align: center;
		    width: 100%;
		    position: relative;
		    vertical-align: middle;
		    right: 50%;
		    @media (max-width:767px){
				right: 0;
			}
			.btn-round-video {
		    	border-radius: 100%;
				background-color: rgba(0, 127, 190, 0.82);
				position: absolute;
				width: 50px;
				height: 50px;
				top: 50%;
				left: 50%;
				transform: translateY(-50%);
				margin-left: 45px;
				@media (max-width:767px){
					margin-left: -25px;
				}
				i {
					color: $white;
					font-size: 25px;
				    position: absolute;
				    width: 100%;
				    height: 100%;
				    top: 0;
				    left: 2px;
				    padding: 7px;
				}
		    }
		    .btn-video{
		    	@media (max-width:767px){
					margin-left: 0;
				}
		    }
		    .v-center {
		    	display: table-cell;
			    vertical-align: middle;
			    height: 75vh;

			    .lightbox-video {
				    position: absolute;
				    top: 22%;
				    right: 47%;
				    width: 100%;
				    height: 100%;
					/*@media (max-width:767px){
						.btn-video{
							margin-left: 0;
						}
					}*/
				    .btn-round-video {
				    	border-radius: 100%;
						background-color: rgba(0, 127, 190, 0.82);
						position: absolute;
						width: 50px;
						height: 50px;
						top: 25%;
						left: 50%;
						transform: translateX(-50%);
						margin-left: 45px;
						/*@media (max-width:767px){
							margin-left: 0;
						}*/
						i {
							color: $white;
							font-size: 25px;
						    position: absolute;
						    width: 100%;
						    height: 100%;
						    top: 0;
						    left: 2px;
						    padding: 7px;
						}
				    }
				}
		    }
		}
	}
	@media (max-width:991px){
		.partial-bg {
			margin: 20px 0px;

			.bg-wrapper {
				display: block;

				.v-center {
					display: block;
					vertical-align: initial;
					height: auto;

					.lightbox-video {
						position: relative;
						height: auto;
						top: 0;
						right: 0;

						.btn-round-video {
							top: 45%;
						}
					}
				}
			}
		}
	}

	p{
		@include section-paragraph;
		font-size: $text__size--eta;
	    line-height: 1.625em;
	    color: #767676;
	    font-weight: 400;
	    margin-bottom: 30px;
	}
	.section-subtitle{
		font-size: $text__size--delta;
	    font-style: italic;
	    margin-top: 0;
	    margin-bottom: 40px;
	    text-transform: lowercase;
	    line-height: 1;
	}
}
.video2{
	background-size: cover;
	.video-wrapper{
		// position: relative;
		// top: 50%;
		// transform: translateY(50%);
		padding: 200px 0;
		color: #fff;
		h2{
			margin-top: 0;
		}
		p{
			// font-size: $text__size--delta;
			margin-bottom: 0;
		}
		.link-content{
			display: inline-block;
			height: 100px;
			width: 100px;
			line-height: 100px;
			background-color: #fff;
			border-radius: 100%;
			position: relative;
			font-size: $text__size--delta;
			color: $color__primary--dark;
			margin-bottom: 40px;

			&::before{
				content: '';
				height: 120px;
				width: 120px;
				position: absolute;
				left: -10px;
				top: -10px;
				border: 10px solid rgba(255, 255, 255, 0.3);
				border-radius: 100%;
			}
		}
	}	
}

.video-grid-2-col{
	.vg-wrap-1,.vg-wrap-2{
		position: relative;
		width: 100%;
		position: relative;
		height: 60vh;
		background-size: cover;
		float: left;

		@media (min-width: 768px){
			width: 50%;
		}

		a{
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 20;
			transition: all 0.4s;

			&:hover h3{
				color: $white;
			}

			&:hover span{
				background-color: $accent;
			}

			&:hover i{
				color: $white;
			}

			.link-content{
				position: absolute;
				left: 50%;
				top:50%;
				transform: translateX(-50%) translateY(-50%);
			}

			h3{
				font-size: $text__size--eta;
				line-height: 1.7;
				text-transform: capitalize;
				color: #c7c7c7;
				transition: all 0.4s;

				@media (min-width: 600px){
					font-size: $text__size--delta;
				}
			}
			span{
				display: inline-block;
				background-color: $white;
				width: 50px;
				height: 50px;
				border-radius: 50px;
				position: relative;
				margin-bottom: 20px;
				transition: all 0.4s;

				i{
					font-size: 24px;
					position: absolute;
					left: 50%;
					top:50%;
					transform: translateX(-50%) translateY(-50%);
					color: $accent;
					transition: all 0.4s;
				}
			}
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.7);
			z-index: 19;
		}
	}
	.vg-wrap-1{
		background-image: url('../images/video/grid2.jpg');
	}
	.vg-wrap-2{
		background-image: url('../images/video/grid3.jpg');
	}
}