@media (min-width: 768px){
  .img-wrap{
      padding: 15px;
    }
}

.img-box{
  position: relative;
  overflow: hidden;
}

.img-box img{
  width:calc(100% + 60px);
  height:calc(100% + 60px);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
  transition: opacity 0.35s, transform 0.45s;
  -webkit-transform: translate3d(-40px,0,0);
  transform: translate3d(-40px,0,0);
}

.img-box:hover img{
  transform: translate3d(0,0,0);
}

.img-box:hover .img-caption{
  bottom: 0;
}

.grid-item-wrap{
  position:relative;
  overflow: hidden;
}
.grid-item-wrap:hover .img-caption{
  bottom: 0;
}

.galleryBoxed1Col .img-caption{
	margin: 10px 0 15px;
}

.img-caption{
  background-image:
    linear-gradient(
      to top, 
      rgba(17,17,17,0.9), rgba(17,17,17,0)
    );
  padding: 15px;
  position: absolute;
  top:auto;
  bottom: -52px;
  width: 100%;
  transition: all 0.4s;

  span{
    color: $white;
    font-size: $text__size--eta;
    text-transform: capitalize;
    font-weight: 600;
  }
}

.gallery-grid-item{
  // width:100%;
  padding: 15px;
  // float: left;
  // overflow: hidden;
  // box-sizing: padding-box;

  @media (min-width: 767px){
    width: 50%;
  }

  @media (min-width: 992px){
    width: 25%;
  }

  &.grid-item-2x{
    width: 100%;

    @media (min-width: 768px){
      width: 50%;
    }

    @media (min-width: 992px){
      width: 50%;
    }
  }
  }

.caption-text{
  float: left;
}

.caption-social{
  float: right;

  span a{
    color: $white;
      i{
        display: inline-block;
        margin-left: 7px;
      }
    }
}

.author-meta span{
  font-size: $text__size--eta;
}
.img-wrapper{
  background-color: $color__primary--dark;  
  padding: 35px;
}
.img-sidebar{
  padding: 30px;
  background-color: $white;
  position: relative;
  height: 88vh;
  .img-title{
    font-size: $text__size--delta;
  }
  .sidebar-header{
    margin-bottom: 30px;
  }
  .sidebar-content{
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 30px;
  }

  .img-meta{
    ul li{
      font-size: $text__size--eta;
      margin-bottom: 10px;
      span{
        display: inline-block;
        margin-right: 10px;
        font-weight: 600;
      }
    }
  }
}

.gutter .img-box{
  margin-bottom: 30px;
}

.gallery-pad{
  padding-top: 30px;
}

.no-gutter.gallery-pad{
  padding-bottom: 30px;
}


/*********************************************************
============== magnific popup transition ================
**********************************************************/

// .mfp-arrow:before, .mfp-arrow:after {
//     content: ' ';
// }

// .mfp-arrow-right {
//     right: 27%;
// }

// .mfp-arrow-left {
//     left: 1%;
// }

// .mfp-arrow-right:before {
//     font-family: 'ionicons';
//     content: '\f3d1';
//     border: none;
//     font-size: 48px;
//     z-index: 1000;
//     color: $white;
//     margin: 0;
// }

// .mfp-arrow-right:after {
//     border-left: none;
//     margin-left: 0;
// }

// .mfp-arrow-left:before {
//     font-family: 'ionicons';
//     content: '\f3cf';
//     border: none;
//     font-size: 48px;
//     z-index: 1000;
//     color: $white;
//     margin: 0;
// }

// .mfp-arrow-left:after {
//     border-right: none;
//     margin-left: 0;
// }

// .mfp-arrow{
//   width:0;
//   height:0;
// }

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-s-loading .mfp-arrow { 
   display: none;
}