/*Header Section*/
/****************/
.header{
  color: $white;
  position: relative;
  .down-arrow{
    position: absolute;
    left: calc(50% - 30px);
    bottom: 0px;
    width: 60px;
    height: 60px;
    color: #FFF;
    text-align: center;
    font-size: $text__size--gamma;
    line-height: 0;
    text-decoration: none;
    z-index: 99;

    &:hover, &:active, &:focus{
      color: #fff;
      text-decoration: none;
    }
    
    span{
      display: block;
    }

    /* ----------- iPhone 4 and 4S ----------- */
    /* Portrait and Landscape */
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
    }
  }

  a.down{
    background-color: transparent;
    width: 40px;
    height: 40px;
    color: #fff;
    border: 1px solid #fff;
    z-index: 1;
    text-align: center;
    line-height: 1.7;
    font-size: 26px;
    margin-top: 45px;
    bottom: auto;
    &:hover{
      background-color: #fff;
      color: $color__primary--dark;
    }
  }

  &:before{
    @include overlay;
    background-color: rgba(0,0,0,0.5);
  }

  .mbYTP_wrapper{
    z-index: -1 !important;
  }

  .header-bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.header-video{
    &:before{
      @include overlay(rgba(0,0,0,0.5));
    }    
  }


  .intro-box{
    display: table;
    width: 100%;
    
    .intro{
      display: table-cell;
      vertical-align: middle;
      height: 100vh;
      position: relative;
      z-index: 1;


      /* ----------- iPhone 4 and 4S vh fix ----------- */

      /* Portrait and Landscape */
      @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2) {
          padding: 80px 0;
          height: auto;
      }
      
      .header-title{
        font-family: $title_font;
        font-weight: 700;
        line-height: 1;
        margin-top: 67px;
        margin-bottom: 0;
        @media (max-width: 47.9rem){
          font-size: $text__size--gamma;
        }
        @include word-wrap;
      }
      .header-subtitle{
        font-weight: 400;
        margin: 10px 0 30px;
        font-style: italic;
        color: $color__primary--light;
        font-size: $text__size--delta;
        @media (max-width: 47.9rem){
          font-size: $text__size--eta;
        }
      }
      .mountain-btn-group{
        margin-top: 15px;
        .btn{
          margin-right: 15px;
          @media (max-width:768px){
            margin-right: 5px;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}

.fashion-heading{
  .mountain-btn-group{
    margin-top: 50px;
  }
}

/* ============== Header variation styles ============== */

.gradient-header {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
  background: -moz-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(5,193,255,1) 83%, rgba(0,0,0,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,0,0,1)), color-stop(83%, rgba(5,193,255,1)), color-stop(100%, rgba(0,0,0,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(5,193,255,1) 83%, rgba(0,0,0,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(5,193,255,1) 83%, rgba(0,0,0,1) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(5,193,255,1) 83%, rgba(0,0,0,1) 100%); /* ie10+ */
  background: linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(5,193,255,1) 83%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#FF0000',GradientType=1 ); /* ie6-9 */
}




/*------------------------------  Header Text Rotator --------------------------*/

.header-text-rotator {
  .intro-box {
    text-align: left;

    .intro {
      .header-title {
        margin-bottom: 0px;
      }

      .header-subtitle {
        width: 100%;

        @media (min-width:768px){
          width: 50%;
        }
      }

      .mtn-btn-group {
        margin-top: 50px;

        .btn {
          margin-right: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }

  &:before{
    @include overlay;
    background-color: rgba(0,0,0,0.2);
  }
}

/*------------------------------  Owl Carousel Header --------------------------*/
// Animation
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fade-in {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fade-in.one {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.fade-in.two {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay:1.2s;
  animation-delay: 1.2s;
}

.fade-in.three {
  -webkit-animation-delay: 1.6s;
  -moz-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.headerCarousel {
  .item {
    .header-bg {
      &:before{
        @include overlay;
        background-color: rgba(0,0,0,0.5);
        transition: all 0.4s ease-in-out;
      }
    }
  }
  .owl-nav{
    .owl-prev, .owl-next{
      position: absolute;
      top: calc(50% - 35px);
      background-color: transparent;
      width: 60px;
      color: #fff;
      text-align: center;
      font-size: 50px;
      transition: all 0.4s ease-in-out;
    }
    &:hover{
      .owl-prev, .owl-next{
        background-color: #fff;
        color: #111;
      }
    }
    .owl-prev{
      left: 0;
    }
    .owl-next{
      right: 0;
    }
  }
}

/*-----------------------  Classic Header ---------------------*/

.classic-header{
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center center;

  .pad-classic {
    padding-top: 250px;
    padding-bottom: 150px;

    .header-title {
      color: $white;

      h1 {
        margin-bottom: 0;
        font-weight: 700;
      }

      .header-subtitle{
        font-weight: 400;
        font-size: $text__size--delta;
        margin: 0;
        color: #fff;
        font-style: italic;
        letter-spacing: 1px;
        line-height: 1.4;
        @media (max-width: 47.9rem){
          font-size: $text__size--eta;
        }
      }
    }
  }

  &:before{
    @include overlay(rgba(0,0,0,0.6));
  }
}
@media (max-width: 768px){
    .classic-header{
      .pad-classic{
      padding-top: 140px;
      padding-bottom: 97px;
    }
  }
}
   

/*----------------------------  Video Background Header---------------------*/
.header-video {
  background: url(../images/header/videobg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  i{
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
  }
}

.video-controls-box {
  position: absolute !important;
  bottom: 40px;
  left: 0;
  width: 100%;
  z-index: 99;

  a {
    display: inline-block;
    color: #fff;
    font-size: 20px;
    margin: 0 20px 0 0;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

/*----------------------------  Subscription Page Header---------------------*/

.subscription-header {
  .intro-box {
    .intro {
      form {
        margin-top: 70px;
      }
      .header-btn-group {
        .btn-mountain-underlined {
          color: #fff;
          font-size: 1.414rem;
        }
      }
    }
  }
  &:before{
    @include overlay;
    background-color: rgba(0,0,0,0);
  }
}

/*-------------------------  Coming Soon Header ---------------------------*/

.coming-soon-header {

    .container-countdown {
        // margin: 60px 0;
    }

    .countdown-box {
        display: inline-block;
        margin: 0 20px;
        position: relative;

        &::after {
            content: ':';
            position: absolute;
            font-size: 45px;
            top: calc(50% - 50px);
            right: -25px;
        }

        &:last-child::after {
            content: '';
        }

        &:nth-child(2)::after {
            content: '';
        }

        .counter {
            display: block;
            border: 2px solid #fff;
            font-size: 45px;
            width: 100px;
        }
    }
}

.corporate-header-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.registration-2col{
  position: relative;
  background-size:cover;
  background-position: center center;
  z-index: 4;

  @media (min-width: 768px){
    height: 100vh;
  }

  h3{
    font-size: $text__size--delta;
    text-transform: capitalize;
    font-weight: 600;
    color:#555;
  }

  blockquote{
    position: relative;
    margin-bottom: 0;
    margin-top: 30px;
    color: #777;
    font-size: 16px;
    border-left: none;
    line-height: 1.7;
    padding: 0px;

    footer{
      background-color: transparent;
      padding: 20px 0 0 0;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 70%;
      color: #999;

      &:before{
        font-weight: 600;
      }
    }

  }

  &:after{
    content: '';
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9)
  }

  .content-wrap{
    position: relative;
    z-index: 5;
    display: table;
    height: 100%;
    width: 100%;


    .content{

      @media (min-width: 768px){
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .testimonial-wrap{
     @media (min-width: 768px){
        margin-top: 10px;
      }
  }

  .form-wrapper{
    background-color: #fff;
    padding: 25px;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;

    @media (min-width: 768px){
       margin-bottom: 0;
    }

    h4{
      font-size: $text__size--eta;
      text-transform: uppercase;
      font-weight: 600;
      color:#666;
      line-height: 1;
      margin-bottom: 6px;
    }

    h5{
      color: #999;
      margin-bottom: 20px;
    }

    input:focus{
      outline: none;
      box-shadow: none;
    }

    .fname{
      margin-bottom: 10px;
    }
    .pass{
      margin-bottom: 20px;
    }
  }
}


.landing-header-1{
  padding: 80px 0;
  position: relative;
  background-size:cover;
  background-position: center center;
  z-index: 4;

  @media (min-width: 768px){
    height: 100vh;
    padding: 0;
  }

  &:after{
    content: '';
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9)
  }

.content-wrap{
    position: relative;
    z-index: 5;
    display: table;
    height: 100%;
    width: 100%;


    .content{
      text-align: center;

      h2{
        font-size: $text__size--gamma;
        margin-bottom: 20px;
        line-height: 1.3;
      }

      h3{
        font-size: $text__size--delta;
        font-weight: 300;
        margin-bottom: 50px;
      }

      .trial-btn, .subscribe-btn{
        padding: 0;
        border: none;
        display: block;

        @media (min-width: 768px){
          display: inline-block;
        }
      }

      .trial-btn a, .subscribe-btn a{
        text-transform: uppercase;
        font-size: $text__size--epsilon;
        letter-spacing: 1px;
        color: $white;
        padding: 9px 30px;

        @media (min-width: 600px){
          padding: 9px 73px;
        }
      }

      .trial-btn a{
        background-color: $accent;
      }

      .subscribe-btn a{
        background-color: #be0f00;
      }

      .btn-devider{
        display: inline-block;
        margin: 15px 0;
        color: #999;
        font-size: $text__size--eta;
        text-transform: uppercase;
        font-size: $text__size--epsilon;

        @media (min-width: 768px){
           margin: 0 15px;
        }
      }

      @media (min-width: 768px){
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

}

.split-header{
  position: relative;
  height: 100vh;
  /*overflow: hidden;*/
  div[class*='col-']:first-child{
    padding: 0;
  }
  @media (max-width: 767px)
  {
    height: auto;
    >div[class*='col-']:last-child{
      padding: 4.875em 0;
      position: relative;
      top: 0;
      left: 0;
    }
    >div[class*='col-']:first-child{
      position: relative;
      height: 32.5em;
      padding: 0;
    }
  }
  div[class*='col-'] {
      position: absolute;
      height: 100%;
      top: 0;
  }
  .bg-img-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: cover !important;
    background-position: 50% 50% !important;
    background: #000;
    @media (max-width: 767px){
      position: relative;
    }
  }
  .pos-left{
    left:0;
  }
  .pos-right{
    right: 0;
  }
  .splitheader-content{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    right: 10px;
    padding: 0 50px;
    @media (max-width: 767px){
      padding: 0 15px;
      top: 0;
      transform:none;
    }
    p{
      font-weight: 400;
      font-size: 1.414rem;
      letter-spacing: 1px;
      line-height: 1.4;
      margin-bottom: 50px;
      font-family: $alt_font;
      color: #838383;
      font-size: 1.214rem;
      text-transform: uppercase;
    }
    h1{
      @media (min-width: 768px){
        margin-bottom: 0;
      }
    }
  }
}

/* OWL CAROUSEL */
.carousel-items-wraper{
  overflow: hidden;
}
// Header with social icons
.header-social{
  &:before{
    background-color: rgba(0, 0, 0, 0.1);
  }
  .wid-social-icons2{
    ul{
      li{
        a{
          border-color: #fff;
          border-radius: 0;
          span{
            color: #fff;
          }
          &:hover{
            background-color: #fff;
          }
          &:hover span{
            color: $color__primary--dark;
          }
        }
      }
    }
  }
}

// Header text button
.header-text-button{
  .btn{
    // margin-top: 20px !important; 
  }
  .button-text{
    display: inline-block;
  }
}

// Header for Fitness
.header-multi-text{
  text-transform: uppercase;
  .intro{
    padding-top: 110px;
  }
  &:before{
    background-color: rgba(0, 0, 0, 0.1);
  }
  .header-bg{
    //background-position: 0;
  }
  .header-title{
    margin-bottom: 0;
    text-transform: none;
    // font-size: $text__size--beta;
    span{
      letter-spacing: 0.8rem;
      font-size: $text__size--eta;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  .header-subtitle{
    font-size: $text__size--beta !important;
    position: relative;
    margin-top: 0 !important;
    &:after{
      content: '';
      height: 45px;
      width: 2px;
      background-color: #fff;
      position: absolute;
      bottom: -50px;
      left: calc(50% - 1px);
    }
  }
  .btn{
    border-radius: 0;
    color: #fff;
    border-color: #fff;
    margin-top: 50px !important;
    &:hover{
      background-color: #fff;
      color: $color__primary--dark;
    }
  }
}
.header-shape{
  .header-bg{
    position: none;
    .intro-box{
      .intro{
        position: inherit;
        padding-top: 50px;
          .shape{
          width: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
        }
        .down{
          margin-top: 30px;
        }
        .title-box{
          z-index: 3;
          img{
            max-width: 240px;
          }
          .header-title{
            font-size: 45px;
            text-transform: uppercase;
            margin-top: 10px;
            margin-bottom: 0;
          }
          .header-subtitle{
            letter-spacing: 14px;
            text-transform: uppercase;
            font-size: 32px;
            margin-top: -10px;
          }
        }
      }
    }
  }     
}


// Header image
.header-img{
  // position: relative;
  img{
    margin-right: auto;
    margin-left: auto;
  }
  // .down{
  //   position: absolute !important;
  //   bottom: 100px;
  //   left: calc(50% - 20px);
  // }
}