// Mixin
@mixin dimension($width, $height, $padding, $margin, $bg, $color){
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  background-color: $bg;
  color: $color;
}

// Global transition smoothness
@mixin smoothTransition(){
  transition: all 0.2s ease-in-out;
}

// Extend
.box{
  transform: rotateZ(45deg);
  border-radius: $primary-border-radius;
}

body{
  position: relative;
}


.section{
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 47.9rem){
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.marginBottom10{
  margin-bottom: 10px;
}
.marginBottom20{
  margin-bottom: 20px;
}
.marginBottom30{
  margin-bottom: 30px;
}
.header-classic{
  padding-bottom: 60px;
}
.section-subtitle{
  color: $color_text_gray;
  // font-family: $title_font;
}
@mixin section-paragraph{
  // margin-top: 45px;
  font-size: $text__size--delta;
  line-height: 2.121rem;
  font-weight: 300;
}

//overlay
@mixin overlay($bgColor:rgba(0,0,0,0.3)){
  content: '';
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  background-color: $bgColor;
}
a:focus{
  outline: none;
}

ul{
  padding: 0;
  list-style-type: none;
}
.img-full{
  width: 100%;
}


//buttons

@mixin btn-mtn{
  border-radius: $primary-border-radius;
  padding: 18px 65px;
  border: 4px;
  @include smoothTransition;
  &.btn-sm{
    padding: 8px 20px;;
  }
  @media (max-width:768px){
    padding: 8px 25px;
  }
}
.btn{
  letter-spacing: 2px;
  font-size: 12px;
  font-family: $alt_font;
  text-transform: uppercase;
  margin-bottom: 15px;
  max-width: 100%;
  i {
    position: relative;
    top: 2px;
  }
}
.btn-mountain{
  @include btn-mtn;
  background-color: $accent__light;
  color: #fff;
  border-color: $accent__light;
  border-bottom: 4px solid $accent;

  &:hover, &:focus, &:active{
    color: #fff;
    background: lighten($accent__light, 5%);
    border-color: lighten($accent__light, 5%);
    border-bottom-color: lighten($accent, 5%);
  }
}


.btn-mountain-flat{
  @include btn-mtn;
  background-color: $accent;
  color: #fff;
  border-color: $accent;
  box-shadow:  0 3px 8px rgba(0, 0, 0, 0.2);
  
  &:hover, &:focus, &:active{
    color: #fff;
    background: $accent__light;
    border-color: $accent__light;
  }
}

.btn-mountain-o{
  @include btn-mtn;
  color: $accent__light;
  border: 2px solid $accent__light;
  background: transparent;
  &.btn-o-white{
    color: white;
    border-color: white;
  }
  &:hover, &:focus, &:active{
    color: #fff;
    background: $accent__light;
    border-color: $accent__light;
  }
}

.btn-mountain-white-bordered {
  @include btn-mtn;
  color: $white;
  border: 2px solid $white;
  background: transparent;
  &:hover, &:focus, &:active{
    color: #4A5670;
    background: $white;
    border-color: $white;
  }
}

.btn-mountain-underlined {
  @include btn-mtn;
  color: $white;
  padding: 6px 12px;
  &:after {
    background-color: #eeeeee;
    position: absolute;
    width: 30%;
    left: 10px;
    bottom: 1px;
    border: 1px solid;
    content: "";
    -webkit-transform: rotateY(45deg);
    -moz-transform: rotateY(45deg);
    -ms-transform: rotateY(45deg);
    -o-transform: rotateY(45deg);
    transform: rotateY(45deg);
    -webkit-transition: all 0.75s;
    -moz-transition: all 0.75s;
    -ms-transition: all 0.75s;
    -o-transition: all 0.75s;
    transition: all 0.0.75s;
  }
  &:hover:after {
    width: 55%;
  }
}

.btn-round{
  border-radius: 55px;
}

.btn-list{
  margin-bottom: 15px;
  a {
    margin-right: 15px;
  }
}

.default-mountain-link{
  display: block;
  font-size: $text__size--delta;
  color:  $link-color;
  text-decoration: underline;
  font-weight: 700;
}


// Clean Mountain Button

.btn-clean{
  border: none;
  font-size: $text__size--eta;
  color: #fff;
  text-decoration: none;
  border-radius: 0;
  font-weight: 700;
  text-shadow: none;
  position: relative;
  @include smoothTransition;
  
  &:hover, &:focus, &:active{
    color: #fff;
  }
}

.btn-clean::before,
.btn-clean::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.6);
  content: '';
  opacity: 0;
  @include smoothTransition;
  transform: translateY(-10px);
}

.btn-clean::before {
  top: 0;
  transform: translateY(-10px);
}

.btn-clean::after {
  bottom: 0;
  transform: translateY(10px);
}

.btn-clean:hover,
.btn-clean:focus {
  color: #fff;
}

.btn-clean:hover::before,
.btn-clean:focus::before,
.btn-clean:hover::after,
.btn-clean:focus::after {
  opacity: 1;
  transform: translateY(0px);
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.btn-classic{
  border-bottom: 1px solid $accent;
  color: $accent;
  padding: 5px 0;
  border-radius: 0;
  transition: all 0.4s ease-in-out;
  font-weight: 700;
    span{
      padding-left: 5px;
    }
  &:hover{
    border-color: transparent;
    span{
      padding-left: 10px;
    }
  }
}
.btn-video{
  @include btn-mtn;
  color: $white;
  padding: 0;
  position: relative;
  margin-left: 90px;
  &:hover, &:active, &:focus{
    color: $white;
    outline: none;

    &:before{
      opacity: 0.8;
    }
  }

  &:before{
    @include smoothTransition;
    position: absolute;
    left: -80px;
    top: -26px;
    content: "\f488";
    font-family: 'Ionicons';
    width: 70px;
    height: 70px;
    text-align: center;
    display: inline-block;
    background-color: $white;
    color: $accent;
    border-radius: 40px;
    font-size: 25px;
    line-height: 72px;
    text-indent: 5px;
  }
}

.btn-mountain-link{
  color: $accent;
  padding: 0;
  .btn-icon{
    font-size: $text__size--delta;
    padding:0 5px;
    margin-left: 0px;
    @include smoothTransition;
  }
  &:hover{
    color:$accent;
    .btn-icon{
      margin-left: 10px;
    }
  }
}

  //animation
  //fadeInUp 
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}


@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}


//preloader


body.pace-running main{
  opacity:0; filter:alpha(opacity=0);
}
body.pace-done main{
  opacity:1;
  filter:alpha(opacity=100);
  transition:all 1s
}



// Generic part of a section
.section-description{
  
}

.description-wrapper{
  h3{
    margin-bottom: 35px;
  }

  p{
    font-size:$text__size--delta;
    line-height: $text__size--delta * 1.5;
    color: #676766;
    margin-bottom: 30px;
  }
  
  margin-bottom: 40px;

  @media (min-width:992px){
    margin-bottom: 0;
  }
}
.main-content-wrapper{
  margin-bottom: 40px;

  &:last-child{
    margin-bottom: 0;
  }
  @media (min-width:992px){
    margin-bottom: 0;
  }
}



//alerts
.alert > i {
    display: inline-block;
    margin-right: 6px;
    font-size: 20px;
    position: relative;
    top: 3px;
    left: 0;
    line-height: 0;
}

button.close > i {
    font-size: 12px;
    width: 20px;
    display: inline-block;
    padding: 5px;
}
.alert-mountain {
    border: 2px solid;
    border-radius: 3px;
}

//end alerts


.icon-container > i {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 1.999rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 10px;
    text-align: center;
    box-sizing: content-box;
}


.form-control{
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: $primary-border-radius;
  box-shadow: none;
  height: 36px;
  &.input-lg {
    height: 46px;
    font-size: 18px;
    padding: 6px 12px;
  }
}

::-webkit-input-placeholder {
  color: $color__primary--light;
  font-size:$text__size--epsilon;
}

:-moz-placeholder { /* Firefox 18- */
   color: $color__primary--light;
   font-size:$text__size--epsilon;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $color__primary--light;
   font-size:$text__size--epsilon;  
}

:-ms-input-placeholder {  
   color: $color__primary--light;
   font-size:$text__size--epsilon;  
}


.tab-content {
  margin-top: 20px;
}


/* grid without gutter */

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

/* 5 col grid */

.col-custom-xs-5,
.col-custom-sm-5,
.col-custom-md-5,
.col-custom-lg-5 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-custom-xs-5 {
    width: 20%;
    float: left;
}


@media (min-width: 768px) {
.col-custom-sm-5 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-custom-md-5 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-custom-lg-5 {
        width: 20%;
        float: left;
    }
}

.pb0{
  padding-bottom: 0;
}
.pt0{
  padding-top: 0;
}

figcaption {
    margin-top: 6px;
}


.header-btn-group .btn {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
}

.input-sm{
  width:50%;
}

.privacy h3{
  padding: 15px 0 10px;
}
.title1{
  h2{
    margin-top: 120px;
  }
  h3{
    // margin-top: -10px;
    padding-bottom: 30px;
  }
}
.background-light{
  background-color: #f8f8f8;
}
.intro500{
  text-align: center;
  padding: 100px 0;
  @media (min-width: 1200px){
    padding: 225px 0;
  }
  .btn{
    margin-top: 30px;
  }
}
.sm-push-bottom-120{
  margin-bottom: 120px;
  @media (min-width: 992px){
    margin-bottom: 0px;
  }
}

.bg-white{
  background-color:#fff;
}

.overlay-none:before{
  content:none!important;
}

.pace .pace-progress {
  border-radius: 10px;
}