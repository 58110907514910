.coming-soon-header{

    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100%;

    @media (min-width: 768px){
    	height: 100vh;
    }
    

	h4{
		margin-bottom: 10px;
	}
	.header .intro-box .intro .header-title {
		margin-bottom: 40px
	}
	h4.upper{
		text-transform: uppercase;
	}
	.header-subtitle{
		display: block;
		margin-bottom: 40px;
	}
	#countDown{
		padding: 30px 0 90px;
	}
	.countdown-box{
		margin:0 15px 20px;

		@media (min-width: 768px){
			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}
		}
		@media (min-width: 768px){
			margin-bottom: 0;
		}

		h6{
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 1px;
			margin-top: 0;
		}
		.counter{
		    border: none;
		    margin-bottom: 0;
		}

		&:after{
			content: '';
		}

	}
}

.coming-soon-form{
	input{
		display: block;
		width: 100%;
		height:50px;
	}
	input#subscriber-email{
		padding-left: 20px;
		background-color: transparent;
		border: 1px solid rgb(255, 255, 255);
	}

	input[type='email']{
		color: #fff;
		margin-bottom: 10px;
	}
}