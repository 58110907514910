.tab1{
	background-color: #fafafa;
	.tabpanel {
		background-color: #ffffff;
		display: flex;
		justify-content: center;
    	align-items: center;
    	@media (max-width:768px){
        	display: inline;
        }
		.nav-pills{
			li{
				border: 1px solid transparent;
				a{
					font-size: 18px;
					font-family: $alt_font;
					border-radius: 0;
					background-color: transparent;
					color: $color__primary--dark;
					transition: all 0.4s ease-in-out;
					@media(min-width: 768px){
						border-left: 3px solid transparent;
					}
					span{
						padding-right: 10px;
					}
					&:active, &:hover, &:focus{
						background-color: transparent;
						color: $accent;
					}
				}
				&.active, &:hover{
					a{
						color: $accent;
						@media(min-width: 768px){
							border-left: 3px solid $accent;
						}
					}			 
				}
				&.active{
					background-color: #fff;
					position: relative;
					border-color: $white*0.95;
					@media(min-width: 768px){
						border-right-color: transparent;
					}

					&:after{
						content: '';
						position: absolute;
						background-color: #fff;
						width: 31px;
						height: 47px;
						border-top: 1px solid $white*0.95;
						border-bottom: 1px solid $white*0.95;
						right: -32px;
						z-index: 11;
						top: -1px;
						@media (max-width: 767px){
							width: 100%;
							height: 3px;
							left: 0;
							right: 0;
							bottom: -30px;
							top: 100%;
							border-color: transparent;
							background-color: #fff;
						}
					}
				}
			}
		}
		.tab-content{
			margin-top: 0;
			padding: 60px;
			background-color: #fff;
			border: 1px solid $white*0.95;
		}
		.section-title{
			margin-bottom: 5px;
		}
		.section-subtitle{
			position: relative;
			margin-bottom: 50px;
			font-size: 1.25rem;
			line-height: 1.5;
			font-style: italic;
			&:after{
				content: '';
				position: absolute;
				left: 0;
				bottom: -15px;
				background-color: $accent;
				width: 60px;
				height: 2px;
			}
		}
	}
}