/*Footer*/
/********/
footer{
  padding: 25px 0px;
  background-color: #000;
  position: relative;
  
  @media (max-width: 47.9rem){
    text-align: center;
  }
    p{
      color: #fff;
      font-weight: 400;
      span {
        display: block;
        font-size: $text__size--epsilon;
        color: $color__primary--light;
        font-weight: 400;
        a{
          font-family: $alt_font;
          font-size: inherit;
          text-transform: uppercase;
          color: inherit;
          font-weight: inherit;
          padding-right: 5px;
          transition: all 0.2s ease-in-out;
          &:hover{
            color: #fff;
          }

          &:last-child{
            border: none;
            padding-left: 7px;
          }
        }

      }
    }
    
  a{
    color: $white;
    font-size: $text__size--eta;
  }
  .social-icons{
    text-align: center;
    padding: 10px 0;
    @media (min-width: 48rem){
      float: right;
    }
    .footer-social{
      color: $color__primary--light;
      display: inline-block;
      padding: 10px;
      transition: all 0.2s ease-in-out;
      &:hover{
          color: #fff;
      }
    }
  }
}

.footer-widgets{
  background-color: $color__primary--dark;
  position: relative;

  .mountainWidget > p, .mountainWidget a{
    color: $color__primary--light;
  }

  .entry-title{
    h5{
      color: #fff;
    }
  }

  .mountainWidget a:hover{
    color: #fff;
  }

  h4{
    color:$white;
  }

  .r45 {
    transform: rotate(-45deg);
   }

  .footer-btn{
    position: absolute;
    left: 50%;
    margin-left: -14px;
    top: -14px;
    background-color: $color__primary--dark;
    text-align: center;
    font-size: 1.414rem;
    width: 30px;
    height: 30px;
    padding: 0px;
    transform: rotate(45deg);
    z-index: 11;
    border-radius: $primary-border-radius;

    span{
      color: #fff;
      font-size:16px;
      display: inline-block;
      text-align: center;
      transition: all 0.2s ease-in-out;

    }

    &:hover{
      span{
         transform: translateY(-5px);
      }
    }
  }
}