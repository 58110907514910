/*About Section*/
/***************/
.about{
  background-color: #fff;
  p{
    @include section-paragraph;
    font-size: 1.414rem;
    line-height: 1.414;
    font-weight: 300;
    margin-bottom: 15px;
    color: #5d5d5d;
  }
  h3{
    margin-bottom: 15px;
    margin-top: 75px;
    font-weight: 700;
  }
  img{
    margin-bottom: 40px;

    @media (min-width: 992px){
      margin-bottom: 0;
    }
    @media (max-width: 991px){
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media (min-width: 992px){
    padding-top: 120px;
  }

  @media (max-width: 768px){
    padding-top: 16px;
  }
}
@media (max-width: 768px){
    .about-us-1{
      padding:50px 0;
    }
    .about{
      h3{
        margin-top: 10px;
      }
      p{
        font-size: 1rem;
      }
    }
}
.personal_about_section{
  h3{
    margin-top: 0;
  }
}
.about-us-1{
  background-color:#f7f7f7;
}

.about-us-bigbg-wrap{
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  @media (min-width: 768px){
    height: 100vh;
  }
  .bg-image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .bg-image-wrap{
    /* margin-bottom: 30px; */

    @media (min-width: 768px){
      position: absolute;
      height: 100%;
      left: 0;
      margin-bottom: 0;
      // height: 70vh;
    }
  }

  .content{
    // position: absolute;
    @media (min-width: 768px){
      display: table;
      height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
    }
    .content-wrap{
      @media (min-width: 768px){
        display: table-cell;
        vertical-align: middle;
        padding: 3em;
      }
      
      @media (max-width: 767px){
        padding: 15px;
      }
      h2{
        margin-bottom: 30px;
      }

      p{
        line-height: 1.7;
        margin-bottom: 45px;
        @media (max-width: 767px){
          margin-bottom: 15px;
        }
      }
    }
   
  }

}

.about-us-5{
  .logo-wrap{
    position: relative;
    background-color: $accent;
    width:200px;
    height: 200px;
    border-radius: 50%;
    margin: 0 auto 30px;

    @media (min-width:992px){
      margin: 0;
    }

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%,-50%,0);
    }
  }

  .text-wrap span{
    font-size: $text__size--gamma;
    font-weight: 300;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 15px;
    line-height: 65px;
    strong{
      font-weight: 300;
      color: $accent;
    }
  }
}

.about-us-6{
  h3{
    font-family: $title_font;
    margin-bottom: 30px;
  }
  p.about-main-para{
    font-size: $text__size--delta;
    font-weight: 300;
    line-height: 1.7;
    width: 90%;
    margin-bottom: 30px;
  }
  .features{
    margin-top: 40px;

    @media (min-width:992px){
      margin-top: 0;
    }
  }
  .features p{
    font-size: $text__size--eta;
    line-height: 1.7;
    margin-bottom: 30px;
    color: $color__text--dark;
  }

  h5{
    font-size: $text__size--eta;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.about-us-7-wrap{
  position: relative;
  background-color: #f7f7f7;

  .bg-image{
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
  .bg-image-wrap{
    padding: 0px;

    /* @media (min-width: 768px){
      position: absolute;
      height: 100%;
      left: 0;
      margin-bottom: 0;
    } */
  }

  .content{
    position: relative;
    width: 100%;
    display: table;
    height:100vh;
    /* padding-bottom: 80px; */
    form{
      margin-top: 40px;
      line-height: 2;
      font-size: $text__size--delta;
      input[type="text"],
      input[type="email"],
      textarea{
        font-size: $text__size--delta;
      }
      .btn-mountain-flat{
        margin-top: 40px;
      }
      ::-webkit-input-placeholder {
       font-size:$text__size--delta;
      }

      :-moz-placeholder { /* Firefox 18- */
         font-size:$text__size--delta;  
      }

      ::-moz-placeholder {  /* Firefox 19+ */
         font-size:$text__size--delta;  
      }

      :-ms-input-placeholder {  
         font-size:$text__size--delta;  
      }
      .form-group{
        margin-top: -10px;
        margin-left: 15px;
      }
    }
    .form-control{
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      border-bottom-color: #ddd;
      border-radius: 0;
      padding: 0 0 0 5px;
      height: auto;
    }

    @media (min-width: 768px){
    }

    .content-wrap{
      display: table-cell;
      vertical-align: middle;
      /* padding: 0 30px; */
      padding:80px 15px 80px 40px;
    }
   
  }

}
.about-us-8{
  h3{
    margin-bottom: 30px;
    color: $color__primary--light;
  }
  .btn-sm{
    margin-top: 30px;
  }
  // .btn-clean{
  //   color: $color__primary--dark;

  //   &:hover, &:focus, &:active{
  //     color: $color__primary--dark;
  //   }
  // .btn-clean::before,
  // .btn-clean::after {
  //     background: rgba(0, 0, 0, 0.6);
  //   }
  // }
}

.corporate-about-6{
  background-color: #f7f7f7;
}

.about-us-bigbg-wrap.about-bigbg-grid{
  .content{
    min-height: 70vh;
  }
  .content-wrap{
    padding-top: 60px;
    .single-grid{
      margin-bottom: 30px;

      @media (min-width:768px){
        margin-bottom: 0px;
      }
      
    }

    div{
      font-family: $alt_font;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      color: #181622;
    }
    .bigbg-grid-row{
      
      @media (min-width:768px){
        margin-bottom: 60px;
      }
    }

    i{
      font-size:48px;
      display: block;
      text-align: center;
      margin-bottom: 20px;
      color: #999;
      line-height: 1;
      }
    @media (min-width:768px){
      padding: 0 7%;
    }
  }

}

.about12{
  .btn-classic{
    margin: 20px auto 100px;
  }
  .about-img-box{
    text-transform: uppercase;
    text-align: center;
    padding: 0 60px;
    img{
      width: 100%;
    }
    .img-title{
      padding: 30px;
    }
    h4{
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0;
    }
    p{
      color: #999;
      font-family: $alt_font;
      font-size: $text__size--epsilon;
      margin-top: 0;
    }
  }
}

.wiget-wraper{
    padding: 15px 25px;
    .title-underline{
      border-bottom: 2px solid #007fbe;
      margin-bottom: 25px;

    }
  }
.wiget-title{
  text-transform: capitalize;
  .highlight-text{
    color: #007fbe;
  }
}

.colored-promo-boxs{
  color: #fff;
  .wiget-wraper{
    padding:50px 30px;
  }
  .wiget-title{
    margin-bottom: 25px;
  }

  .btn{
    margin-top: 50px;
  }
  
}
 

@for $i from 1 through 3 {
  .color-promo-box:nth-of-type(#{$i}){
    background-color: darken(#007fbe, 2*$i);
    transition: opacity .2s #{$i * .1}s ease-in;
  }
}
.color-promo-box{
  position: relative;
  z-index: 2;
  background-size: cover;
  background-color: gray;
}
$overlay:rgba(0,0,0,0.5);
.color-promo-box:after{
  @include overlay($overlay);
  z-index: -1;
}

.about-us-features{
  h3{
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
    color: #343434;
    font-weight: 600;
    font-variant-ligatures: common-ligatures;
    margin-bottom: 30px;
  }
  p{
    font-family: 'Lora', serif;
    color: #767676;
    font-size: 1.2rem;
  }
  .fswrap{
    margin-bottom: 25px;
    p{
      font-size: 1rem;
      line-height: 1.5;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Lora', 'Helvetica', 'Arial', sans-serif;
      color: #767676;
      font-weight: 400;
    }
    .fsicon{
      margin-top: -3px;
    }
  }
}

.push--top--60{
  margin-top: 60px;
}

.aboutUsImageRight{
  .content{
    left: 0;
  }
  .bg-image-wrap{
    right: 0;
    left: auto;
    .bg-image{
      right: 0;
      left: auto;
    }
  }
}


.aboutUsImageleft{
  .content{
    left: 0;
  }
  .bg-image-wrap{
    right: auto;
    left: 0;
    @media (min-width: 768px){
      height: 100%;
    }
    .bg-image{
      right: auto;
      left: 0;
    }
  }
}

.contact-split{
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  .content{
      padding: 3em;
      text-align: left;
      @media (min-width:768px){
      display: table;
      height: 100%;
      min-height: 70vh;
      padding: 3em;
      text-align: right;
      .content-wrap{
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
  .bg-image-wrap{
      display: none;
    @media (min-width:768px){
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      padding: 0;
      visibility: visible;
    }
    .bg-image{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: cover!important;
      background-position: center center;
      img{
        min-width: 100%;
        @media (min-width:768px){
          display: none;
        }
      }
    }
  }
}