.innerheader{
	background-color: #000;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	.pad-large{
		padding-top: 200px;
		padding-bottom: 200px;
	}
	.pad-small{
		padding-top: 150px;
		padding-bottom: 150px;
	}
	.pad-xs {
		padding-top: 200px;
		padding-bottom: 75px;
	}
	&:before{
		@include overlay;
	}
	@media (min-width: 768px){
		.inner-breadcrumb{
			text-align: right;
		}
	}
	.inner-title{
		padding-left: 15px;
	}
	.inner-title, .inner-breadcrumb{
		color: #fff;
		a{
			color: #fff;
			@include smoothTransition;
		}
	}
	.breadcrumb{
		background-color: transparent;
		text-align: center;
	}

	&.narrow{
		background-color: #F5F5F5;
    	border-bottom: 1px solid #EEE;

    	.pad-large {
    		padding: 140px 0 50px 0;
		}

		.inner-title, .inner-breadcrumb{
			color: $color__primary;
			a{
				color: $color__primary;	
			}
		}
		&:before{
			display: none;
		}
	}
}
.innerheader2{
	.inner-title{
		margin-bottom: 40px;
		h3{
			text-transform: uppercase;
			font-size: $text__size--beta;
			margin-bottom: 10px;
			font-weight: 700;
		}
		p{
			font-size: $text__size--gamma;
			/*line-height: $text__size--gamma*1.5;*/
			line-height: 2.2rem;
		}
	}
	@media (max-width: 767px){
		.pad-large{
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.inner-title{
			margin-bottom: 25px;

			h3{
				margin-bottom: 15px;
				font-size: $text__size--gamma;
			}
			p{
				font-size: $text__size--eta;
				line-height: 1.5rem;
			}
		}
	}
}