.not-found{
	background-image: url(../images/404/404.jpg);
	background-size: cover;
	background-position: bottom;
	width: 100%;
	height: 100vh;
	text-align: center;

	h1{
		font-family: $title_font;
		font-weight: 700;
		font-size: 100px;
		margin-bottom: 0px;
		margin-top: 55vh;
	}
}